// Reset styles
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  scroll-behavior: smooth;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Mixins
@mixin mq($from) {
  @media (max-width: $from) {
    @content;
  }
}

@mixin no-select {
  user-select: none;
}

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap&subset=latin-ext");

@font-face {
  font-family: "Impact";
  src: url("./fonts/Impact.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Impact";
  src: url("./fonts/Impact.ttf") format("truetype"),
    url("./fonts/Impact.svg#Impact") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  @include mq(640px) {
    font-size: 14px;
  }
}

// Variables
$red: #cf2a36;

// Typography

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  font-family: "Impact";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  @include mq(640px) {
    font-size: 24px;
  }
}

.telephone {
  text-decoration: none;
  @include mq(640px) {
    text-decoration: underline;
  }
}

ul {
  &.bulleted {
    li {
      z-index: -2;
      font-size: 12px;
      line-height: 160%;
      position: relative;
      padding-left: 13px;
      &:before {
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        border: 2px solid $red;
        border-radius: 50%;
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.strong {
  font-weight: 700;
}

// Buttons
button {
  border: none;
  background-color: white;
  padding: 0;
}
.btn {
  font-family: "Roboto", sans-serif;
  background-color: $red;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color color 0.3s;
  @include no-select;
  &:disabled {
    background-color: #cacaca;
    cursor: not-allowed;
  }
  &.full {
    display: block;
    width: 100%;
  }
  &.link {
    background-color: white;
    color: black;
    text-decoration: underline;
    font-weight: 400;
  }
  span {
    position: relative;
    svg {
      width: 20px;
      fill: white;
      animation: spin 1s linear infinite;
      height: 20px;
      position: absolute;
      left: -30px;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.button-group {
  margin-top: 16px;
  .btn {
    margin: 8px 8px;
  }
}

.cta {
  font-size: 16px;
  padding: 20px 40px;
  line-height: 20px;
  min-width: 288px;
}

.action {
  cursor: pointer;
}

.back-link {
  font-size: 14px;
  padding: 16px 8px;
  display: inline-block;
  font-weight: 700;
  padding-left: 30px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

//Forms
.input-field.text {
  margin-bottom: 16px;
  label {
    display: block;
    padding-left: 0;
    line-height: 19px;
    margin-bottom: 5px;
    .required {
      color: $red;
      font-weight: 700;
    }
  }
  input[type="text"],
  input[type="telephone"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: 44px;
    display: block;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #a8a8a8;
    padding: 16px;
  }
}

.input-field.checkbox {
  position: relative;
  input[type="checkbox"] {
    opacity: 0;
  }
  .checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    transition: all 0.3s;
    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s;
    }
    &.checked {
      border-color: $red;
      background-color: $red;
      svg {
        opacity: 1;
      }
    }
  }
  label {
    padding-left: 12px;
    @include no-select;
  }
}

.input-field.radio {
  margin: 10px 0;
  position: relative;
  input {
    opacity: 0;
  }
  label {
    padding-left: 12px;
    cursor: pointer;
  }
  .check {
    width: 16px;
    height: 16px;
    position: absolute;
    display: block;
    top: 50%;
    transform: translatey(-50%);
    left: 0;
    background-color: white;
    border: 1px solid #a8a8a8;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.3s;
    cursor: pointer;
    &.checked {
      border: 6px solid $red;
    }
  }
}

.input-field.textarea {
  label {
    display: block;
    margin-bottom: 10px;
    line-height: 19px;
    font-weight: 700;
  }
  textarea {
    width: 100%;
    border-radius: 4px;
    padding: 14px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 16px;
    box-sizing: border-box;
  }
}
.input-field.adress {
  input {
    display: inline-block;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #a8a8a8;
    padding: 16px;
  }
  .btn {
    height: 44px;
  }
}

.naseptavac-wrapper {
  position: relative;
}

.naseptavac {
  position: absolute;
  top: 45px;
  left: 0;
  display: block;
  height: auto;
  background-color: white;
  border: 1px solid #a8a8a8;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  border-radius: 4px;
}

.naseptavac-item {
  padding: 10px 12px;
  font-size: 13px;
  cursor: pointer;
}

.input-field {
  input {
    transition: all 0.3s;
    &.invalid {
      border-color: $red !important;
      color: $red;
    }
    &.valid {
      border-color: #5ac726 !important;
      color: #5ac726;
    }
  }
  .error {
    color: $red;
    font-size: 12px;
    padding-top: 5px;
  }
}

// Unique classes

// // Header
.logo {
  svg {
    width: 88px;
    height: 88px;
    @include mq(640px) {
      width: 48px;
      height: 48px;
    }
  }
}

.site-title {
  padding-left: 30px;
  font-weight: 700;
  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    display: block;
  }
  @include mq(640px) {
    padding-left: 15px;
  }
}

.head-contact {
  font-weight: 700;
  a {
    color: black;
    padding-left: 40px;
    display: inline-block;
    position: relative;
    svg {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// // Footer
.steps {
  background-color: $red;
  width: 100%;
  padding: 36px 0;
  margin-bottom: 50px;
  .step {
    &:not(:last-child) {
      @include mq(640px) {
        margin-bottom: 16px;
      }
    }
  }
  span {
    display: inline-block;
    &.step-number {
      font-size: 30px;
      font-family: "Impact";
      font-style: normal;
      font-weight: normal;
      color: $red;
      background-color: white;
      border-radius: 50%;
      text-align: center;
      width: 64px;
      padding: 17px 0;
    }
    &.step-text {
      color: white;
      line-height: 1.5;
      width: 200px;
      padding-left: 16px;
      font-size: 14px;
    }
  }
}

.footer-headline {
  display: block;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
}

.footer-contact {
  a {
    display: block;
    padding: 8px 0;
    color: black;
    text-decoration: none;
    line-height: 20px;
    font-size: 14px;
    padding-left: 40px;
    position: relative;
    z-index: 0;
    svg {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.footer-map {
  display: block;
  margin: auto;
  max-width: 100%;
}

.footer-info {
  .cell {
    @include mq(640px) {
      &:not(:last-child) {
        margin-bottom: 64px;
      }
    }
  }
}

.copyright {
  font-size: 11px;
  color: #3e3e3e;
  line-height: 1.5;
  font-weight: 300;
  margin: 100px auto;
  a {
    color: #3e3e3e;
    text-decoration: none;
    position: relative;
    padding-right: 50px;
    z-index: 0;
    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      path {
        transition: all 0.3s;
      }
    }
    &:hover {
      svg {
        path {
          fill: #eaa700;
        }
      }
    }
  }
  @include mq(640px) {
    text-align: center;
    margin: 32px auto;
    .cell {
      margin-bottom: 32px;
      text-align: center;
    }
  }
}

// // Homepage

.hero {
  margin-bottom: 100px;
  @include mq(640px) {
    margin-bottom: 50px;
  }
}

.hero p {
  line-height: 1.5;
}

.hl-top {
  font-size: 24px;
  font-weight: 700;
  display: block;
  text-align: center;
  margin-bottom: 6px;
  @include mq(640px) {
    font-size: 16px;
  }
}

.bullets {
  margin-top: 12px;
  span {
    font-weight: 700;
    display: inline-block;
    padding: 0 15px;
    position: relative;
    &:not(:first-child) {
      &:before {
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        border: 2px solid $red;
        border-radius: 50%;
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.alergens {
  margin: 50px 0;
  p {
    line-height: 1.5;
    a {
      color: black;
    }
  }
}

// // // Anchors
#anchors-wrapper {
  width: 210px;
  position: relative;
}

.anchors {
  width: 210px;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e2;
    }
    a {
      font-size: 14px;
      padding: 10px 30px 10px 10px;
      color: #222;
      display: block;
      text-decoration: none;
      position: relative;
      transition: all 0.3s;
      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translatey(-50%);
        path {
          stroke: white;
          transition: all 0.3s;
        }
      }
      &:hover {
        color: black;
      }
      &.current,
      &.active {
        color: black;
        svg {
          path {
            stroke: black;
          }
        }
      }
    }
  }
}

// // // Product list
.section-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 16px;
  border-bottom: 1px solid #949494;
  display: block;
  position: relative;
  transition: all 0.3s;
  &.expanded {
    border-bottom: 1px solid #e2e2e2;
    svg {
      transform: translatey(-50%) rotate(180deg);
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translatey(-50%);
    transition: all 0.3s;
  }
  @include mq(640px) {
    cursor: pointer;
    font-size: 18px;
    padding: 12px 0;
  }
}

.section-content {
  @include mq(640px) {
    display: none;
    &.expanded {
      display: block;
    }
  }
}

.loading {
  svg {
    fill: #848484;
    max-width: 48px;
    max-height: 48px;
    display: block;
    margin: auto;
    animation: spin 1s linear infinite;
  }
}

.product-head {
  margin-bottom: 18px;
}

.product-title {
  font-family: "Impact";
  font-style: normal;
  font-weight: normal;
  display: block;
  line-height: 150%;
  .sale-label {
    color: #f06508;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 700;
    display: inline-block;
    margin-left: 8px;
    line-height: 1;
  }
}

.product-description {
  font-size: 13px;
  font-weight: 300;
  line-height: 150%;
}

.product-price {
  font-size: 16px;
  font-weight: 700;
}

.product-qty {
  display: inline-block;
  font-size: 14px;
  color: #5ac726;
  font-weight: 700;
  position: relative;
  padding-right: 16px;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    path {
      stroke: #5ac726;
    }
  }
}

.product-item {
  padding: 16px 0;
  &:last-child {
    margin-bottom: 16px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}

// Layout
header {
  padding: 30px 0;
  @include mq(1199px) {
    padding: 0.9375rem 0;
  }
  @include mq(640px) {
    margin-bottom: 1rem;
  }
}

// // Cart

.empty-cart {
  padding: 32px;
  svg {
    max-width: 100px;
    height: auto;
    display: block;
    margin: auto;
    fill: #cacaca;
    margin-bottom: 32px;
  }
  span {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
  }
}

#large-cart-wrapper {
  width: 305px !important;
  position: relative;
}

.sticky-cart {
  width: 305px !important;
  @include mq(640px) {
    width: auto !important;
    margin-bottom: 1rem;
    .cart-headline {
      font-size: 18px;
    }
  }
}

.cart-panel {
  position: fixed;
  top: 100vh;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 2;
  transition: top 0.5s ease-in-out;
  &.open {
    top: 0;
  }
}

.cart-topbar {
  background-color: $red;
  padding: 8px;
  color: white;
  .title {
    font-weight: 700;
  }
  .empty {
    font-size: 13px;
    opacity: 0.75;
  }
}

.cart-bar {
  background-color: $red;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  font-weight: 700;
  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.25);
  z-index: 1;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 13px;
  }
}

.cart-wrapper {
  padding: 1rem;
}

.cart-content {
  border-bottom: 1px solid #e2e2e2;
}
.cart-headline {
  font-family: "Impact";
  font-style: normal;
  font-weight: normal;
  display: block;
  font-size: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e2e2;
}

.cart-item {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  padding: 8px 0;
  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 16px;
  }
}

.cart-item--qty {
  font-size: 13px;
  font-weight: 400;
}

.cart-item--headline {
  max-width: 150px;
}

.cart-item--additives {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  text-transform: lowercase;
  max-width: 150px;
}

.cart-item--price {
  display: inline-block;
  width: 75px;
  margin: 0 5px;
  @include no-select;
}

.qty-control {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  top: 3px;
  transition: all 0.3s;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  }
}

.surcharge {
  border-bottom: 1px solid #e2e2e2;
}

.surcharge-item {
  padding: 8px 0;
  color: #5a5a5a;
  font-size: 14px;
  line-height: 1.5;
  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 16px;
  }
}

.surcharge-item--price {
  font-weight: 700;
}

.total-price {
  padding: 16px 0;
  font-weight: 700;
}

.total-price--price {
  font-size: 20px;
  color: #91e967;
}

// Tooltip
.tooltip {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 8px;
  cursor: pointer;
  span {
    background-color: #222;
    color: white;
    font-size: 9px;
    line-height: 16px;
    font-weight: 400;
    display: block;
    border-radius: 50%;
    text-align: center;
  }
  .tooltip-content {
    position: absolute;
    display: none;
    width: 190px;
    height: auto;
    font-size: 12px;
    line-height: 1.2;
    top: 50%;
    transform: translatey(-50%);
    font-weight: 400;
    padding: 5px;
    background-color: #222;
    color: white;
    border-radius: 4px;
    &.is-open {
      display: block;
    }
    &.left {
      left: -210px;
    }
    &.right {
      right: -210px;
    }
  }
}

// Modal
.modal-open {
  overflow-y: hidden;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  .modal-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(1, 1, 1, 0.5);
  }
  .modal-panel {
    position: absolute;
    width: 750px;
    max-height: 750px;
    max-width: 97%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    @include mq(640px) {
      max-height: 97%;
    }
  }
  &.small {
    .modal-panel {
      width: 370px;
      max-width: 97%;
      .modal-content {
        .modal-headline {
          text-align: center;
        }
      }
    }
  }
  &.large {
    .modal-panel {
      width: 1100px;
      max-width: 100vw;
    }
  }
  &.full {
    .modal-panel {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.modal-content {
  padding: 48px 32px;
  display: block;
  position: relative;
  p {
    padding-bottom: 16px;
  }
  @include mq(640px) {
    padding: 48px 16px 16px 16px;
  }
}

.modal-headline {
  font-family: "Impact";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  padding-bottom: 36px;
  line-height: 1.5;
  display: block;
  @include mq(640px) {
    padding-bottom: 13px;
  }
}

.modal-product-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  display: block;
  line-height: 1.3;
}

.modal-product-description {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  display: block;
  line-height: 1.3;
  color: #6a6a6a;
  span {
    color: $red;
    text-transform: lowercase;
  }
}

.ingrediences {
  margin-top: 48px;
  margin-bottom: 48px;
}

.ingredience-item {
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 10px;
  .checked {
    color: $red;
  }
}

.ingredience-select {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 16px;
  position: relative;
  @include mq(640px) {
    padding-right: 0;
    max-height: 300px;
  }
}

.scroll-box-overlay {
  position: relative;
  top: -50px;
  width: 100%;
  display: block;
  height: 50px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.close-btn {
  font-size: 13px;
  position: absolute;
  top: 20px;
  right: 30px;
  padding-right: 5px;
  cursor: pointer;
  @include no-select;
  &:hover {
    text-decoration: underline;
  }
  svg {
    position: absolute;
    right: -10px;
    top: 1px;
  }
}

.cart-topbar-close {
  position: relative;
  top: auto;
  right: 8px;
  svg {
    rect {
      fill: white;
    }
  }
}

// Checkout
.checkout-fields {
  margin-bottom: 1rem;
}
.checkoutPanel {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 18px;
  .checkoutPanel-head {
    padding: 0 26px;
    min-height: 72px;
    border-bottom-color: transparent;
    transition: border-bottom-color 0.5s;
    cursor: pointer;
    &.is-open {
      border-bottom: 1px solid #d6d6d6;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      position: relative;
      .checkoutPanel-fields {
        font-weight: 400;
        font-size: 14px;
        color: #3e3e3e;
        display: block;
        &.strong {
          font-size: 16px;
          color: black;
          font-weight: 700;
        }
      }
      .label {
        display: inline-block;
        text-align: left;
        margin-left: 42px;
      }
      .icon {
        display: inline-block !important;
        width: 16px;
        height: 19px;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translatey(-50%);
        margin-right: 22px;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          margin: auto;
        }
      }
    }
  }
  .checkoutPanel-content {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    .checkoutPanel-content--wrap {
      padding: 26px 26px;
    }
    &.is-open {
      display: block;
      max-height: 500px;
      overflow: visible;
    }
  }
  .link {
    font-size: 14px;
    color: #888888;
    transition: all 0.3s;
    text-decoration: underline;
    cursor: pointer;
    @include no-select;
    &:hover {
      color: #444444;
    }
  }
}

.checkout-error {
  color: black;
  background-color: lighten($red, 50%);
  border: 1px solid $red;
  padding: 14px;
  border-radius: 6px;
  .error-sign {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: white;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
    margin-right: 14px;
    background-color: $red;
    border-radius: 50%;
  }
  p {
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 1.5;
  }
  ul {
    list-style-type: disc;
    margin-left: 24px;
    font-size: 14px;
    li {
      &:not(:last-child) {
        padding-bottom: 6px;
      }
    }
  }
}

.mb-3 {
  margin-bottom: 30px;
  &.s-mb-0 {
    @include mq(640px) {
      margin-bottom: 0;
    }
  }
}

.accept-gdpr {
  max-width: 50%;
  display: block;
  margin: 30px auto 60px auto;
  @include mq(1024px) {
    max-width: 80%;
  }
  @include mq(640px) {
    max-width: 100%;
  }
}

.open-hours {
  margin-top: 24px;
  font-size: 14px;
  color: black;
  display: inline-block;
  padding: 12px 12px 12px 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  font-weight: 700;
  min-height: 14px;
  .control {
    border-radius: 50%;
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    animation: blink 1s infinite;
  }
  &.is-open {
    display: inline-block;
    background-color: lighten(#91e967, 30%);
    border-color: #91e967;
    .control {
      background-color: #91e967;
    }
  }
  &.not-open {
    display: inline-block;
    background-color: lighten($red, 40%);
    border-color: $red;
    .control {
      background-color: $red;
    }
  }
}

.info {
  font-size: 14px;
  color: black;
  display: inline-block;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  font-weight: 400;
  min-height: 14px;
  text-align: center;
  line-height: 1.5;
  &.success {
    background-color: lighten(#91e967, 30%);
    border-color: #91e967;
  }
}

//Login form
.my-account {
  display: inline-block;
  margin-left: 16px;
  span {
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    padding-left: 36px;
    svg {
      position: absolute;
      left: 12px;
    }
  }
  &__menu {
    display: inline-block;
    ul {
      list-style-type: none;
      li {
        display: inline-block;
        padding: 0.6rem 0.8rem;
        margin: 0 0.2rem;
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        &:hover {
          background-color: lighten(#000, 95%);
        }
        &.active {
          background-color: lighten(#000, 90%);
          &:hover {
            background-color: lighten(#000, 90%);
          }
        }
      }
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    padding-right: 4rem;
  }
  &__head {
    border-bottom: 1px solid #949494;
    padding: 16px 0;
    margin-bottom: 2rem;
    svg {
      height: 32px;
      width: auto;
      margin-right: 8px;
    }
  }
}

.label--new {
  background-color: $red;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 8px;
}

.my-account__user {
  svg {
    height: 42px;
    width: auto;
    margin-right: 8px;
  }
  &--logged-in {
    display: block;
    font-weight: 700;
  }
  &--log-out {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  span {
    font-weight: 700;
    color: #b3b3b3;
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    width: 100%;
    border-bottom: 2px solid #b3b3b3;
    &.active {
      color: black;
      border-bottom-color: black;
    }
  }
}

.login-error {
  font-size: 14px;
  color: black;
  background-color: lighten($red, 50%);
  border: 1px solid $red;
  text-align: center;
  padding: 8px 8px 8px 8px !important;
  line-height: 1.5;
  margin-bottom: 16px;
}

.login-divider {
  display: block;
  margin-bottom: 16px;
  text-align: center;
}

.fb-login {
  display: block;
  background-color: #4267b2;
  color: white;
  width: 100%;
  padding: 8px 0;
  border-radius: 6px;
  margin-bottom: 16px;
  cursor: pointer;
  line-height: 24px;
  position: relative;
  padding-left: 44px;
  svg {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px 0 0 6px;
    position: absolute;
    height: 16px;
    left: 0;
    top: 0;
    padding: 12px;
    path {
      fill: white;
    }
  }
}

.orders {
  font-size: 15px;
  .order {
    padding: 16px 8px;
    border-bottom: 1px solid #d0d0d0;
    cursor: pointer;
    &.unread {
      background-color: #e3ecff;
    }
  }
  .order-head {
    font-weight: 700;
    padding: 16px 8px;
    border-bottom: 1px solid #848484;
  }
  .no-orders {
    p {
      text-align: center;
      padding: 16px;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
